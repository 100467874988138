<template>
<div>
  <v-data-table
    :headers="headers"
    :items="paginated"
    :loading="loading"
    hide-default-footer
    :items-per-page="10"
  >
    <v-progress-linear
      slot="progress"
      color="primary"
      indeterminate
    ></v-progress-linear>
    <template v-slot:body="{ items }">
      <tbody>
        <tr v-for="item in items" :key="item.id">
          <td>
            {{ item.currency.toUpperCase() }} {{ item.total | local_numbers }}
          </td>
          <td>{{ item.status | payout_status }}</td>
          <td>{{ item.arrival_at | utc_to_local_datetime("DD-MM-YYYY") }}</td>
          <td>{{ item.created_at | utc_to_local_datetime("DD-MM-YYYY") }}</td>
          <td>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  color="primary"
                  @click="downloadPdf(item)"
                  :disabled="!payoutAccount"
                >
                  <!--<v-icon small>fal fa-cog</v-icon>-->
                  <v-icon small>fal fa-file-pdf</v-icon>
                </v-btn>
              </template>
              <span>Download PDF</span>
            </v-tooltip>
          </td>
        </tr>
      </tbody>
    </template>

    <div class="text-center" slot="no-data">
      No payouts found
    </div>
  </v-data-table>
  <div class="p-4 d-flex justify-end">
   <v-btn :disabled="page === 0" 
      @click="prevPage" class="mr-2" color="primary">Previous</v-btn>
    <v-btn :disabled="page >= pageCount - 1" 
      @click="nextPage" color="primary">Next</v-btn>
  </div>
 
  </div>
    

</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import payout_status from "@/helpers/filters/payout_status";
import { jsPDF } from "jspdf";

export default {
  filters: {
    local_numbers,
    utc_to_local_datetime,
    payout_status
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
      size: { type: Number, required: false, default: 10 },

     firstLoad: {
      type: Boolean,
      required: false,
      default: false
    },
    payoutAccount: {
      // type: Object,
      required: false,
      default: null
    }
  },
  data: () => ({
    headers: [
      { text: "Amount", sortable: false },
      { text: "Status", sortable: false },
      { text: "Expected payout date", sortable: false },
      { text: "Created", sortable: false },
      { text: "", sortable: false }
    ],
    pdfLine: 0,
    page: 0
  }),
  methods: {
     nextPage() {
       this.$emit('next')
      this.page++;
    },
    prevPage() {
      this.page--;
    },
    setLine(length = 10) {
      this.pdfLine += length;
    },
    downloadPdf(payout) {
      // Reset default line
      this.pdfLine = 10;

      // Use local number format
      let amount = this.$options.filters.local_numbers(payout.amount);
      let vat = this.$options.filters.local_numbers(payout.vat);

      let pdfName =
        "Payout " +
        this.$options.filters.utc_to_local_datetime(
          payout.created_at,
          "DD-MM-YYYY"
        );

      let doc = new jsPDF();
      const defaultFont = doc.getFont();
      doc.setFontSize(20);
      doc.text("Make Influence payout", 7, this.pdfLine);

      // Payout from
      doc.setFontSize(10);
      doc.setFont(defaultFont.fontName, defaultFont.fontStyle, "bold");
      this.setLine(10);
      doc.text("Sender", 7, this.pdfLine, "left");

      // info style
      doc.setFontSize(10);
      doc.setFont(defaultFont.fontName, defaultFont.fontStyle, "normal");

      this.setLine(5);
      doc.text("Make Influence ApS", 7, this.pdfLine, "left");
      this.setLine(5);
      doc.text("DK40061320", 7, this.pdfLine, "left");
      this.setLine(5);
      doc.text("Strandgade 70, 1", 7, this.pdfLine, "left");
      this.setLine(5);
      doc.text("1401 København K", 7, this.pdfLine, "left");
      this.setLine(5);
      doc.text("DK", 7, this.pdfLine, "left");

      // Payout to header style
      doc.setFontSize(10);
      doc.setFont(defaultFont.fontName, defaultFont.fontStyle, "bold");
      this.setLine(10);
      doc.text("Recipient", 7, this.pdfLine, "left");

      // Payout to info style
      doc.setFontSize(10);
      doc.setFont(defaultFont.fontName, defaultFont.fontStyle, "normal");

      let company = this.payoutAccount.company ?? "";
      let address = this.payoutAccount.address ?? "";
      let postalCode = this.payoutAccount.postal_code ?? "";
      let city = this.payoutAccount.city ?? "";
      let country = "";

      if (this.payoutAccount.account_type === 1 && company) {
        this.setLine(5);
        doc.text("" + company, 7, this.pdfLine, "left");
      }

      if (this.payoutAccount.tax_id) {
        this.setLine(5);
        doc.text("" + this.payoutAccount.tax_id, 7, this.pdfLine, "left");
      }

      this.setLine(5);
      doc.text(address, 7, this.pdfLine, "left");
      this.setLine(5);
      doc.text(postalCode + " " + city, 7, this.pdfLine, "left");
      this.setLine(5);
      doc.text(country, 7, this.pdfLine, "left");
      this.setLine(5);

      if (this.payoutAccount.email) {
        doc.text("" + this.payoutAccount.email, 7, this.pdfLine, "left");
      }

      // Payout info
      doc.setFontSize(10);

      // Payout ID
      this.setLine(20);
      doc.setFont(defaultFont.fontName, defaultFont.fontStyle, "bold");
      doc.text("Payout-id", 7, this.pdfLine, "left");
      doc.setFont(defaultFont.fontName, defaultFont.fontStyle, "normal");
      doc.text("" + payout.stripe_key, 45, this.pdfLine, "left");
      // doc.text("132sasd", 30, this.pdfLine, 'left');

      // Payout creation
      this.setLine(5);
      doc.setFont(defaultFont.fontName, defaultFont.fontStyle, "bold");
      doc.text("Dato", 7, this.pdfLine, "left");
      doc.setFont(defaultFont.fontName, defaultFont.fontStyle, "normal");
      doc.text(
        "" +
          this.$options.filters.utc_to_local_datetime(
            payout.created_at,
            "DD-MM-YYYY"
          ),
        45,
        this.pdfLine,
        "left"
      );

      // Payout arrival
      this.setLine(5);
      doc.setFont(defaultFont.fontName, defaultFont.fontStyle, "bold");
      doc.text("Payout date", 7, this.pdfLine, "left");
      doc.setFont(defaultFont.fontName, defaultFont.fontStyle, "normal");
      doc.text(
        "" +
          this.$options.filters.utc_to_local_datetime(
            payout.arrival_at,
            "DD-MM-YYYY"
          ),
        45,
        this.pdfLine,
        "left"
      );

      // Headline styles
      doc.setFontSize(10);
      doc.setFont(defaultFont.fontName, defaultFont.fontStyle, "bold");

      this.setLine(20);

      let width = doc.internal.pageSize.getWidth();

      let rightCol1 = width - 10;
      let rightCol2 = width - 40;
      let rightCol3 = width - 70;
      let rightCol4 = width - 100;

      // Line header - Unit text
      doc.text("Product", rightCol4, this.pdfLine, "right");
      // Line header - Unit price
      doc.text("Unit price", rightCol3, this.pdfLine, "right");
      // Line header - Vat
      doc.text("VAT", rightCol2, this.pdfLine, "right");
      // Line header - Total
      doc.text("Total", rightCol1, this.pdfLine, "right");

      // Line styles
      doc.setFont(defaultFont.fontName, defaultFont.fontStyle, "normal");
      // Set new line
      this.setLine(6);

      // Line - unit text
      doc.text("Total commission", rightCol4, this.pdfLine, "right");
      // Line - unit price
      doc.text("" + amount, rightCol3, this.pdfLine, "right");
      // Line - Vat
      doc.text(vat, rightCol2, this.pdfLine, "right");
      // Line - Total
      doc.text("" + amount, rightCol1, this.pdfLine, "right");

      // Headline styles
      doc.setFont(defaultFont.fontName, defaultFont.fontStyle, "normal");
      doc.setFontSize(10);

      // Line header - Total without vat
      this.setLine(25);
      doc.text("Total ex. VAT", rightCol2, this.pdfLine, "right");
      // Line - Total without vat
      doc.text("" + amount, rightCol1, this.pdfLine, "right");

      // Line header - Total vat
      this.setLine(6);
      doc.text("25% VAT", rightCol2, this.pdfLine, "right");
      // Line - Total vat
      doc.text("" + vat, rightCol1, this.pdfLine, "right");

      // Line header - Total
      doc.setFont(defaultFont.fontName, defaultFont.fontStyle, "bold");
      this.setLine(6);
      doc.text("Total incl. VAT", rightCol2, this.pdfLine, "right");
      // Line - Total
      doc.text(
        "" + this.$options.filters.local_numbers(payout.amount + payout.vat),
        rightCol1,
        this.pdfLine,
        "right"
      );

      doc.setFont(defaultFont.fontName, defaultFont.fontStyle, "normal");

      // Download
      doc.save(pdfName + ".pdf");
    }
  },
  computed: {
    pageCount() {
      let l = this.items.length,
        s = this.size;
      return Math.round(l / s);
    },
    paginated() {
      const start = this.page * this.size,
        end = start + this.size;
      return this.items.slice(start, end);
    }
  }
};
</script>
